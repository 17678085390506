@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: poppins, sans-serif;
}

body {
    background: #fff;
    color:rgba(14, 1, 1, 0.96);
    padding-top: 80px;
}
/* ----------------------------------------------navbar and logo */
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 8%;
    background: white;
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
}

.logo{
    font-size: 25px;
    font-family: "Poppins", cursive;
    text-decoration: none;
    font-weight: 600;
    color: #1a653d
}
.navbar {
margin-left: 780px;
}
.navbar a {
    font-size: 13px;
    color: black;
    text-decoration: none;
    font-weight: 500;
    margin-left: 35px;
}

.navbar a:hover{
    color: pink

}

/* ---------------------------------------------------------home */
.home {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
}
/* .home{
    height: 40vh;
    display: flex;
    align-items: center;
    padding: 0 10%;
    min-height: calc(70vh - 80px);
} */

.home-content {
    background: white;
    max-width: 700px;
    position: relative;
    z-index: 1;
}

  .home-content h1 .bold {
    font-weight: bold;
  }
  .home-content h1 .thin {
    font-weight: 100;
  }
  .home-content h3 .thin {
    font-weight: 100;
  }
  
  .image-container img {
    /* max-width: 100%; */
    /* height: auto; */
    filter: brightness(100%);
    animation-name: effect;
    animation-duration: 12s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    /* margin-left: -150px */
  }
  
  @media only screen and (max-width: 768px) {
    .home {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
    
    .home-content {
      margin-top: 50px;
      max-width: 100%;
      text-align: center;
    }
    /*--------------------------------------------------bird img  */
    .image-container {
      width: 100%;
      position: static;
      margin-top: -100px;
      display: none; 
    }
    
    .image-container img {
      margin: 0 auto;
      display: block;
    }
  }
  
  @keyframes effect {
    0% {
      filter: brightness(90%);
    }
    25% {
      filter: grayscale(100%);
    }
    50% {
      filter: brightness(50%);
    }
    75% {
      filter: brightness(200%);
    }
    100% {
      filter: brightness(100%);
    }
  }
  
.home-content h1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2;
  }

  .home-content h3 {
    font-size: 24px;
    color:rgba(61, 26, 2, 0.818);
  }
  
  .home-content p {
    font-size: 13px;
    margin: 20px 0 40px;
  }
  /* --------------------------------------------------------btn */
  .home-content .btn-box {
    display: flex;
    justify-content: space-between;
    width:345px;
    height:50px;
  }
  
  .btn-box a {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 100%;
    background: rgb(216, 214, 201);
    border: 2px solid rgb(216, 214, 201);
    border-radius: 9px;
    font-size: 13px;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 1px;
  }
  
  .btn-box a:nth-child(2) {
    background: transparent;
  }

.btn-box a:hover {
  background-color: transparent;
}


/* -------------------- Dark mode -------------------- */

/* Set the background and font colors for the dark mode */
.dark-mode {
  background-color: #1a1a1a;
  color: #e6e6e6;
}

/* Invert the logo color for better visibility in dark mode */
.dark-mode .logo {
  color: #1a653d
}

/* Invert the navbar link colors for better visibility in dark mode */
.dark-mode .navbar a {
  color: #e6e6e6;
}

/* Invert the navbar link hover colors for better visibility in dark mode */
.dark-mode .navbar a:hover {
  color: #ffc0cb;
}

/* Invert the home content colors for better visibility in dark mode */
.dark-mode .home-content {
  background-color: #1a1a1a;
  color: #e6e6e6;
}

/* Invert the home content header colors for better visibility in dark mode */
.dark-mode .home-content h1 {
  color: #e6efec;
}

.dark-mode .home-content h3 {
  color: #d8c1a9;
}

/* Invert the home content button colors for better visibility in dark mode */
.dark-mode .btn-box a {
  background-color: #4b4d4c;
  border-color: #4b4d4c;
  color: #a8aab2;
}

.btn-box a:nth-child(2) {
  background: transparent;
}

.dark-mode .btn-box a:hover {
  background-color: #1a1a1a;
  color: #e6e6e6;
}

.darkmode .footer-section footer p {
  color: white;
}

.dark-mode .header {
  color: #1a1a1a;
}

.dark-mode .header {
  background-color: #1a1a1a;
  color: #e6e6e6;
}
