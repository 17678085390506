
.projects-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
  }
  
  .project-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background-color: #f2f2f2;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transition: box-shadow 0.3s ease;
  }
  
  .project-card:hover {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
  }
  
  .project-heading {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    color: #333;
  }
  
  .project-image {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  
  .project-button {
    background-color: #2c3e50;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .project-button:hover {
    background-color: #34495e;
  }
  