/* .footer-section footer {
  background-color: whitesmoke;
  color: black;
  font-size: 1.2rem;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center; 
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 2rem;
}

.footer-section .social-media-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.footer-section .social-media-links a {
  margin-right: 1rem;
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out;
}

.footer-section .social-media-links a:hover {
  opacity: 1;
}

.footer-section p {
  margin: 0;
  opacity: 0.8;

  text-align: center; 

}

.footer-section p:hover {
  opacity: 1;
} */
.footer-section {
  background-color: transparent;
  color: black;
  padding: 30px;
    text-align: center;
  font-size: 9.5px;
}

.social-media-links {
  display: flex;
  justify-content: center;

}



.social-media-links img {
  width: 30px;
}

p {
  margin: 0;
}
