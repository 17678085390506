body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.about-container {
    max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.profile-image {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.profile-image img {
  width: 100%;
  height: auto;
  display: block;
  transform: scale(1);
  transition: transform 0.3s ease;
}

.profile-image:hover img {
  transform: scale(1.1);
}

.about-info {
  text-align: center;
}

.about-heading {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
}

.about-subheading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.about-text {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.loading-text {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin: 50px;
}
